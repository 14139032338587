<template>
  <div class="my_treat_page">
    <div class="exec_list" v-if="execlist.length > 0">
        <div class="exec_th">
            <span>理疗名称</span>
            <span>执行人</span>
            <span style="width: 18%;">执行状态</span>
        </div>
        <div class="one" v-for="item in execlist" :key="item.id">
            <div class="exec_td">
                <span>{{item.projectname}}</span>
                <span>{{item.execdoctorname}} {{item.isown == '1' ? '(本人)': '(他人)'}}</span>
                <span :class="'status' + item.execstatuscode" style="width: 18%;">{{item.execstatusname}}</span>
            </div>
        </div>


    <!-- <div class="list" style="margin-bottom: 20px; padding: 10px;background-color: #fff;">
      <div class="title" style="margin-bottom: 10px;">签名项目列表</div>
      <table cellspacing="0">
        <tr><td style="width: 40%;">理疗名称</td><td style="width: 35%;">执行人</td><td style="padding: 0 12px;">执行状态</td></tr>
        <tr></tr>
      </table> -->
    </div>
    <div class="wrap1" v-show="showFull">
      <div class="sign-title" style="margin-bottom: 10px;">签名区域</div>
      <canvas class="canvas1" ref="canvas1" />
      <div class="actions">
          <button class="danger" @click="handleClear1" >清除</button>
          <button class="warning" @click="handleUndo1" >撤销</button>
          <button class="primary" @click="handleFull" >横屏</button>
          <button class="success" @click="handlePreview1" >保存</button>
      </div>
    </div>
    <div class="wrap2" v-show="!showFull">
      <div class="actionsWrap">
        <div class="actions">
          <button class="danger" @click="handleClear1" >清除</button>
          <button class="warning" @click="handleUndo1" >撤销</button>
          <button class="primary" @click="handleFull" >竖屏</button>
          <button class="success" @click="handlePreview2" >保存</button>
        </div>
      </div>
      <canvas class="canvas" ref="canvas2" />
    </div>
  </div>
</template>

<script>
import { wxsavesignature,waitsignaturelist} from '@/api/medical'
import SmoothSignature from "smooth-signature";
import { mapGetters } from 'vuex'
import { Toast } from 'vant';
export default {
  name: "mbDemo",
  computed: {
      ...mapGetters([
          'username','clinicid','isrefresh','isclose'
      ]),
  },
  data() {
    return {
      showFull: true,
      serviceid:'',
      imageData:'',
      execlist:[],
      serviceids:[],
      signaturelist:[],
    };
  },
  mounted() {
    
    this.initSignature1();
    this.initSignture2();
   
  },
  created(){
    this.serviceid = this.$route.query.serviceid || ''
    this.searchList()
  },
  methods: {
    // 查询待签名列表
    searchList(){
      let value = {
        username: this.username,
        serviceid: this.serviceid,
      };
     
      waitsignaturelist(value).then(res =>{

        this.signaturelist = res.response_body.list
        if(this.$route.query.isAll == 'true'){
          this.execlist = this.signaturelist
          this.serviceids = this.signaturelist.map(item => item.id)
        }else{
          this.execlist = this.signaturelist.filter(item => item.id ==  this.$route.query.serviceid)
          this.serviceids =  this.serviceid.split(",")
        }

      })
      
    },
    initSignature1() {
      const canvas = this.$refs["canvas1"];
      const options = {
        
        height: 200,
        minWidth: 2,
        maxWidth: 6,
        openSmooth:true,
        // color: "#1890ff",
        bgColor: '#fff',
      };
      this.signature1 = new SmoothSignature(canvas, options);
    },
    initSignture2() {
      const canvas = this.$refs["canvas2"];
      const options = {
        width: window.innerWidth - 120,
        height: window.innerHeight - 80,
        minWidth: 3,
        maxWidth: 10,
        openSmooth:true,
        // color: "#1890ff",
        bgColor: '#fff',
      };
      this.signature2 = new SmoothSignature(canvas, options);
    },
    handleClear1() {
      
      this.signature1.clear();
      this.signature2.clear();
    },
    handleClear2() {
      this.signature1.clear();
      this.signature2.clear();
    },
    handleUndo1() {
      this.signature1.undo();
      this.signature2.undo();
    },
    handleUndo2() {
      this.signature1.undo();
      this.signature2.undo();
    },
    handleFull() {
      this.signature1.clear();
      this.signature2.clear();
      this.showFull = !this.showFull;
    },
    handlePreview1() {
      const isEmpty = this.signature1.isEmpty();
      if (isEmpty) {
        Toast('请签名！')
        return;
      }
      const pngUrl = this.signature1.getPNG();
      let sdata = {
          username: this.username,
          serviceids: this.serviceids,
          signatureimg: pngUrl,
      }

      wxsavesignature(sdata).then(query =>{
        Toast.success({
          message:'保存成功!',
          onClose:()=>{
            this.back()
          }
        });
      })
      .catch(error => {
        Toast({
          message:error.response_head.retinfo.retmsg,
          onClose:()=>{
            this.back()
          }
        });
      });
    },
    handlePreview2() {
      const isEmpty = this.signature2.isEmpty();
      if (isEmpty) {
        Toast('请签名！')
        return;
      }
      const canvas = this.signature2.getRotateCanvas(-90);
      const pngUrl = canvas.toDataURL();
      let sdata = {
          username: this.username,
          serviceids: this.serviceids,
          signatureimg: pngUrl,
      }

      wxsavesignature(sdata).then(query =>{
        Toast.success({
          message:'保存成功!',
          onClose:()=>{
            this.back()
          }
        });
        
      })
      .catch(error => {
        Toast({
          message:error.response_head.retinfo.retmsg,
          onClose:()=>{
            this.back()
          }
        });
      });
      
    },
    back() {
        if (window.history.length <= 1) {
            this.$router.push({path:'/'});
            return false
        } else {
            this.$router.go(-1);
        }
        
    },

  },
};
</script>

<style lang="scss" scoped>
.my_treat_page {
  padding: 16px;
  min-height: calc(100vh);
  background: #fafafa;
  margin-bottom: 0 !important;
  .exec_td {

span{

  &.status2, &.status3{
    color:#FA6400;
  }
  &.status4{
    color:#08a553;
  }
}
}
}
  button {
    height: 32px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: 2px;
  }
  .danger {
    color: #fff;
    background: #ee0a24;
    border: 1px solid #ee0a24;
  }
  .warning {
    color: #fff;
    background: #ff976a;
    border: 1px solid #ff976a;
  }
  .primary {
    color: #fff;
    background: #1989fa;
    border: 1px solid #1989fa;
  }
  .success {
    color: #fff;
    background: #07c160;
    border: 1px solid #07c160;
  }
  canvas {
    border-radius: 10px;
    border: 2px dashed #ccc;
    background-color: #fff;
  }
  .wrap1 {
    height: 100%;
    width: 96%;
    margin: auto;
    padding: 10px;
    background-color: #fff;
    margin-top: 20px;
    .actions {
      display: flex;
      justify-content: space-around;
    }
    canvas{
      width: 100%;
    }
  }
  .wrap2 {
    padding: 15px;
    height: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: center;
    .actionsWrap {
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .canvas {
      flex: 1;
      background-color: #fff;
    }
    .actions {
      margin-right: 10px;
      white-space: nowrap;
      transform: rotate(90deg);
      display: flex;
      button{
          margin-right: 20px;
      }
    }
  }

td{
    border: 1px solid #ccc;
   text-align: center;
}
table{
  border-collapse: collapse;
}

</style>
